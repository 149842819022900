import React from "react";
import { MapPin, Phone, Mail } from "lucide-react";
import Navbar from "../components/nav";
import Footer from "../components/footer";

const ContactPage = () => {
  return (
    <div className="min-h-screen">
      <Navbar />
      {/* Full-width Image Bar */}
      <div className="w-full h-64 relative">
        <img
          src="https://finez.lk/cdn/shop/files/01_11_2048x.jpg?v=1691488783"
          alt="Furnique Header"
          className="w-full h-full object-cover brightness-50"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-5xl font-bold text-white">Contact Us</h1>
        </div>
      </div>

      {/* Content Container */}
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row items-center">
          {/* Contact Information */}
          <div className="w-full md:w-1/2 pr-12 space-y-6 flex flex-col items-center">
            <h2 className="text-3xl font-bold text-gray-900">Get in Touch</h2>
            <div className="flex items-center space-x-4">
              <Mail className="w-8 h-8 text-gray-700" />
              <span className="text-xl text-gray-800">info@furnique.lk</span>
            </div>
            <div className="flex items-center space-x-4">
              <Phone className="w-8 h-8 text-gray-700" />
              <span className="text-xl text-gray-800">+94 71 375 7599</span>
            </div>
            <div className="flex items-center space-x-4">
              <MapPin className="w-8 h-8 text-gray-700" />
              <span className="text-xl text-gray-800">
                Rukmale, Pannipitiya
              </span>
            </div>
          </div>

          {/* Image and Description on Right Side (Hidden on Mobile) */}
          <div className="w-full md:w-1/2 hidden md:block">
            <img
              src="https://contentgrid.homedepot-static.com/hdus/en_US/DTCCOMNEW/Articles/best-furniture-for-your-home-2022-hero.jpg"
              alt="Furnique Showroom"
              className="w-3/4 h-auto object-cover rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
