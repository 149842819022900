import React, { useState } from "react";
import { motion } from "framer-motion";
import Navbar from "../components/nav";
import Footer from "../components/footer";

const ProductsPage = () => {
  const products = [
    {
      title: "Side Table",
      description:
        "A versatile side table that fits perfectly in any living room or bedroom, offering both style and functionality.",
      image: "/api/placeholder/500/300",
      buttonText: "View Product",
    },
    {
      title: "Corner Shelf",
      description:
        "Maximize your space with this elegant corner shelf, ideal for displaying your favorite decor pieces.",
      image: "/api/placeholder/500/303",
      buttonText: "View Product",
    },
    {
      title: "Keys and Letters Holder",
      description:
        "Keep your entryway organized with this stylish holder for keys and letters, ensuring you never misplace them again.",
      image: "/api/placeholder/500/306",
      buttonText: "View Product",
    },
    {
      title: "Wall Mirror With Console Table",
      description:
        "Enhance your hallway with this chic wall mirror and console table combo, perfect for last-minute touch-ups and storage.",
      image: "/api/placeholder/500/309",
      buttonText: "View Product",
    },
  ];

  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const currentProduct = products[currentProductIndex];

  const nextProduct = () => {
    setCurrentProductIndex((prev) => (prev + 1) % products.length);
  };

  const prevProduct = () => {
    setCurrentProductIndex((prev) =>
      prev === 0 ? products.length - 1 : prev - 1,
    );
  };

  return (
    <div className="min-h-screen">
      <Navbar />
      {/* Hero Image Section */}
      <div className="relative h-[500px] overflow-hidden">
        <img
          src="https://finez.lk/cdn/shop/files/01_11_2048x.jpg?v=1691488783"
          alt="Products Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Overlay Title */}
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-6xl font-bold text-white">Our Products</h1>
        </div>
      </div>

      {/* Products Section */}
      <div className="container mx-auto px-4 py-16">
        <motion.div
          key={currentProductIndex}
          className="grid md:grid-cols-2 gap-12 items-center"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Image (40%) */}
          <div className="relative">
            <div className="overflow-hidden rounded-2xl shadow-lg">
              <img
                src={currentProduct.image}
                alt={currentProduct.title}
                className="w-full h-[400px] object-cover"
              />
            </div>
          </div>

          {/* Product Details (60%) */}
          <div>
            <h2 className="text-4xl font-bold mb-6">{currentProduct.title}</h2>
            <p className="text-lg text-gray-700 mb-6">
              {currentProduct.description}
            </p>

            <div className="mt-6">
              <button className="px-6 py-2 text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition">
                {currentProduct.buttonText}
              </button>
            </div>
          </div>
        </motion.div>

        {/* Product Navigation */}
        <div className="flex justify-center space-x-4 mt-12">
          <button
            onClick={prevProduct}
            className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition"
          >
            Previous
          </button>
          <button
            onClick={nextProduct}
            className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition"
          >
            Next
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsPage;
