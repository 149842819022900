import React from "react";
import { motion } from "framer-motion";

const FeaturedSection = () => {
  return (
    <div className="relative w-full h-[600px] overflow-hidden">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src="https://berre.ca/cdn/shop/collections/living-room-furniture-833363.webp?v=1698354202&width=1600"
          alt="Featured Section"
          className="w-full h-full object-cover"
        />

        {/* Black Overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      {/* Content Container */}
      <motion.div
        className="relative z-10 flex flex-col items-center justify-center h-full text-center px-4"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 1,
            ease: "easeOut",
          },
        }}
      >
        <h2 className="text-5xl font-bold text-white mb-6 max-w-3xl">
          Transforming Spaces, Crafting Experiences
        </h2>
        <p className="text-xl text-white/80 max-w-2xl">
          Discover the art of interior design with our curated collection of
          furniture that blends functionality, comfort, and timeless elegance.
        </p>
      </motion.div>
    </div>
  );
};

export default FeaturedSection;
