import React from "react";
import { motion } from "framer-motion";
import product1 from "../media/product1.jpg";
import product2 from "../media/product2.jpg";
import product3 from "../media/product3.jpg";
import product4 from "../media/product4.jpg";

const FeaturedItems = () => {
  const items = [
    {
      title: "Side Table",
      description:
        "A versatile side table that fits perfectly in any living room or bedroom, offering both style and functionality.",
      image: product1,
      buttonText: "View Product",
    },
    {
      title: "Corner Shelf",
      description:
        "Maximize your space with this elegant corner shelf, ideal for displaying your favorite decor pieces.",
      image: product2,
      buttonText: "View Product",
    },
    {
      title: "Keys and Letters Holder",
      description:
        "Keep your entryway organized with this stylish holder for keys and letters, ensuring you never misplace them again.",
      image: product3,
      buttonText: "View Product",
    },
    {
      title: "Wall Mirror With Console Table",
      description:
        "Enhance your hallway with this chic wall mirror and console table combo, perfect for last-minute touch-ups and storage.",
      image: product4,
      buttonText: "View Product",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">
        Featured Pieces
      </h2>
      <div className="grid md:grid-cols-4 gap-8">
        {items.map((item, index) => (
          <motion.div
            key={item.title}
            className="bg-white rounded-2xl shadow-lg overflow-hidden"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.7,
                delay: index * 0.3,
                ease: "easeOut",
              },
            }}
            viewport={{ once: true }}
          >
            <div className="relative h-64 overflow-hidden">
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">
                {item.title}
              </h3>
              <p className="text-gray-600 mb-6 h-20">{item.description}</p>
              
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedItems;
