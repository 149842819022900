import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X } from "lucide-react";
import logo from "../media/3.png";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 pt-4">
      <div className="container mx-auto px-4">
        <div className="relative">
          {/* Navbar Container with Frosted Glass Effect */}
          <div className="bg-white/70 backdrop-blur-md shadow-lg rounded-2xl py-3 px-6">
            <div className="flex justify-between items-center">
              {/* Logo */}
              <a href="/">
                <div className="flex items-center">
                  <img src={logo} alt="Logo" className="h-10" />
                  <h1 className="text-2xl font-semibold text-gray-800 ml-2">
                    FURNIQUE
                  </h1>
                </div>
              </a>

              {/* Desktop Navigation */}
              <div className="hidden md:flex space-x-6">
                <a
                  href="/contact"
                  className="text-gray-800 hover:text-gray-600 transition-colors"
                >
                  Contact
                </a>
              </div>

              {/* Mobile Menu Toggle */}
              <div className="md:hidden">
                <button
                  onClick={toggleMobileMenu}
                  className="text-gray-800 focus:outline-none"
                >
                  {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Menu */}
          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{
                  opacity: 1,
                  height: "auto",
                  transition: { duration: 0.3 },
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                  transition: { duration: 0.2 },
                }}
                className="md:hidden bg-white/70 backdrop-blur-md shadow-lg rounded-b-2xl overflow-hidden"
              >
                <div className="flex flex-col p-4 space-y-4">
                  <a
                    href="/contact"
                    className="text-gray-800 hover:bg-gray-100 p-2 rounded-lg transition-colors"
                  >
                    Contact
                  </a>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
