import React from "react";
import { Facebook, Instagram, Twitter, Linkedin } from "lucide-react";
import footerlogo from "../media/1.png";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Logo and Description */}
          <div>
            <div className="flex justify-center md:justify-start">
              <img
                src={footerlogo}
                alt="Company Logo"
                className="mb-4 w-48 h-48"
              />
            </div>
            <p className="text-gray-300 text-sm">
              Crafting exceptional furniture that transforms your living spaces
              with style, comfort, and timeless design.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-xl font-semibold mb-4">Quick Links</h4>
            <div className="space-y-2">
              <a
                href="/"
                className="text-gray-300 hover:text-white transition-colors block"
              >
                Home
              </a>

              <a
                href="/contact"
                className="text-gray-300 hover:text-white transition-colors block"
              >
                Contact
              </a>
            </div>
          </div>

          {/* Social and Contact */}
          <div>
            <h4 className="text-xl font-semibold mb-4">Connect With Us</h4>
            <div className="flex space-x-4 mb-4">
              <a
                href="https://www.facebook.com/furniquelk/"
                className="text-gray-300 hover:text-white transition-colors"
              >
                <Facebook size={24} />
              </a>
              <a
                href="https://www.instagram.com/furnique_pvt.ltd/"
                className="text-gray-300 hover:text-white transition-colors"
              >
                <Instagram size={24} />
              </a>
            </div>
            <p className="text-gray-400 text-sm">
              Email: info@furnique.lk
              <br />
              Phone: +94 71 375 7599
            </p>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-8 pt-6 text-center">
          <p className="text-gray-400 text-sm">
            © 2024 FURNIQUE. All Rights Reserved. Made with love by{" "}
            <a
              href="https://encloy.com"
              className="text-gray-300 hover:text-white transition-colors"
            >
              Encloy
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
