import React, { useState } from "react";
import { motion } from "framer-motion";

const AboutUs = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="container mx-auto px-4 pt-20 pb-40">
      <div className="grid md:grid-cols-2 gap-24 items-center">
        {/* Left Side Image */}
        <motion.div
          className="relative"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.7,
              ease: "easeOut",
            },
          }}
          viewport={{ once: true }}
        >
          <img
            src="https://contentgrid.homedepot-static.com/hdus/en_US/DTCCOMNEW/Articles/best-furniture-for-your-home-2022-section-1.jpg"
            alt="About Us Main"
            className="w-full h-auto rounded-2xl shadow-lg"
          />

          {/* Hover Image - Visible only on larger screens */}
          <motion.div
            className="hidden md:block absolute -right-16 -bottom-16 w-1/2 z-10"
            initial={{ opacity: 1, scale: 0.9 }}
            animate={{
              scale: isHovered ? 1.05 : 1,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src="https://contentgrid.homedepot-static.com/hdus/en_US/DTCCOMNEW/Articles/best-furniture-for-your-home-2022-hero.jpg"
              alt="Hover Detail"
              className="w-full h-auto rounded-2xl shadow-2xl transition-all duration-300 transform"
            />
          </motion.div>
        </motion.div>

        {/* Right Side Content */}
        <motion.div
          className="md:pr-8"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.7,
              delay: 0.3,
              ease: "easeOut",
            },
          }}
          viewport={{ once: true }}
        >
          <h2 className="text-4xl font-bold mb-6 text-gray-900">About Us.</h2>
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            At our core, we're more than just a furniture company. We're
            artisans dedicated to transforming living spaces through
            meticulously crafted pieces that blend form and function. Each item
            in our collection tells a story of precision, passion, and timeless
            design.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed">
            Our commitment goes beyond creating furniture. We believe in
            sustainable practices, supporting local craftsmen, and delivering
            pieces that become cherished parts of your home's narrative.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutUs;
