import HeroSection from "../components/hero";
import Navbar from "../components/nav";
import FeaturedItems from "../components/items";
import FeaturedSection from "../components/imagetitle";
import AboutUs from "../components/about";
import Footer from "../components/footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <FeaturedItems />
      <FeaturedSection />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default Home;
