import React from "react";
import { motion } from "framer-motion";

const HeroSection = () => {
  return (
    <div className="relative flex flex-col md:flex-row w-full h-screen overflow-hidden">
      <motion.div
        className="w-full md:w-[75%] h-1/2 md:h-full relative"
        initial={{ opacity: 1, x: 0 }}
        animate={{
          x: [0, -50],
          transition: {
            duration: 1.5,
            ease: "easeInOut",
          },
        }}
      >
        <img
          src="https://cdn.shopify.com/s/files/1/0549/5806/3713/files/organic_modern_furniture.jpg?v=1704830139"
          alt="Furniture Hero"
          className="w-full h-full object-cover"
        />
      </motion.div>

      {/* Text Section (25% width on desktop, 100% on mobile) */}
      <motion.div
        className="w-full md:w-[25%] h-1/2 md:h-full flex flex-col justify-center px-8 bg-white"
        initial={{ opacity: 0, x: 50 }}
        animate={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 1,
            delay: 0.5,
            ease: "easeOut",
          },
        }}
      >
        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
          Where Wood Meets Art
        </h1>
        <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
          Discover furniture that transforms houses into homes. Elegant design
          meets unparalleled comfort.
        </p>
        
      </motion.div>
    </div>
  );
};

export default HeroSection;
